import './App.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Navigation } from 'swiper/modules';

function App() {
  return (
    <div className="App">
      <nav className="navbar navbar-expand-lg py-5 fixed-top">
        <div className="container-fluid ps-0">
          <img src="images/Vivekananda college of skill logo.png" alt="logo" className="logo-img" />
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="extra-bg"></div>
          <div className="collapse navbar-collapse ms-5" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item me-5">
                <a className="nav-link fw-bold fs-4 text-light" href="#">
                  Home
                </a>
              </li>
              <li className="nav-item me-5">
                <a className="nav-link fw-bold fs-4 text-light" href="#">
                  About Us
                </a>
              </li>
              <li className="nav-item me-5">
                <a className="nav-link fw-bold fs-4 text-light" href="#">
                  Academics
                </a>
              </li>
              <li className="nav-item me-5">
                <a className="nav-link fw-bold fs-4 text-light" href="#">
                  Admission
                </a>
              </li>
              <li className="nav-item me-5">
                <a className="nav-link fw-bold fs-4 text-light" href="#">
                  Placement
                </a>
              </li>
              <li className="nav-item me-5">
                <a className="nav-link fw-bold fs-4 text-light" href="#">
                  Career
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link fw-bold fs-4 text-light" href="#">
                  Login
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="banner p-5 pb-0">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <h1 className="about-heading">About Us</h1>
              <div className="hr">
                <hr />
              </div>
              <h1 className="main-heading text-uppercase fw-bold"><span className="name-color">Vivekananda college</span> of skill development</h1>
              <div className="d-flex mt-5">
                <button type="button" className="btn shadow-none border-0 register-btn me-4 px-5 py-2 mt-5 fs-3">Register Now</button>
                <button type="button" className="btn shadow-none more-btn px-5 py-2 mt-5 fs-3">Learn More</button>
              </div>
            </div>
            <div className="col-md-6">
              <a href="tel:+919432155145" className="callNumber pe-5 ps-4 py-2 position-absolute end-0 text-light fw-bold"><i className="bi bi-telephone-fill me-3"></i>9432155145</a>
              <img src="images/main-side-bg.jpg" alt="logo" className="main-side-img shadow" />
            </div>
          </div>
        </div>
        <div className="container-fluid middle-gapp px-5">
          <div className="row">
            <div className="col">
              <div className="card mb-3 border-0 background-clr-two py-3">
                <div className="row g-0">
                  <div className="col-md-3 d-flex justify-content-center align-items-center">
                    <i className="fa-solid fa-person-chalkboard"></i>
                  </div>
                  <div className="col-md-9">
                    <div className="card-body">
                      <h5 className="card-title fs-4">Quality Teacher</h5>
                      <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card mb-3 border-0 background-clr py-3">
                <div className="row g-0">
                  <div className="col-md-3 d-flex justify-content-center align-items-center">
                    <i className="fa-solid fa-swatchbook"></i>
                  </div>
                  <div className="col-md-9">
                    <div className="card-body">
                      <h5 className="card-title fs-4">Best Curriculam</h5>
                      <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card mb-3 border-0 background-clr-three py-3">
                <div className="row g-0">
                  <div className="col-md-3 d-flex justify-content-center align-items-center">
                    <i className="fa-solid fa-globe"></i>
                  </div>
                  <div className="col-md-9">
                    <div className="card-body">
                      <h5 className="card-title fs-4">Global Recognition</h5>
                      <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card wel-back py-2">
        <div className="card-body d-flex justify-content-center align-items-center">
          <div className="card bg-transparent border border-light rounded-pill py-2 w-50">
            <div className="card-body">
              <h2 className="text-light fw-bold text-center welcome-text">Welcome to VISD</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="descripTion p-5">
        <div className="social-media px-3 bg-warning">
          <ul className="ps-0">
            <li>
              <a href="#">
                <i className="fa-brands fa-whatsapp mb-3 fs-3"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="fa-brands fa-youtube mb-3 fs-4"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="fa-brands fa-instagram mb-3 fs-4"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="fa-brands fa-facebook mb-3 fs-4"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="fa-brands fa-twitter fs-4"></i>
              </a>
            </li>
          </ul>
        </div>
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-md-6">
              <h1 className="overview-heading">Overview</h1>
              <div className="hr">
                <hr />
              </div>
              <div className="card ps-4">
                <div className="card-body">
                  <h5 className="fs-5 overview-text">
                    Vivekananda College of Skill Development in is a platform dedicated to enhancing professional and technical skills through high-quality training programs. It offers a variety of courses tailored to meet the needs of students, job seekers, and professionals, focusing on practical learning and industry relevance. The website provides resources, tutorials, and hands-on training in fields like IT, software development, digital marketing, and more. Designed to foster career growth, the platform emphasizes skill-building, certifications, and real-world applications. Accessible and user-friendly, it aims to bridge the gap between education and employment, empowering learners to achieve their career goals.
                  </h5>
                </div>
              </div>
              <div className="card ps-4">
                <div className="card-body">
                  <h5 className="fs-5 overview-text">
                    Vivekananda College of Skill Development is a premier online platform committed to empowering learners with industry-relevant skills and certifications. Specializing in IT, software development, digital marketing, and other in-demand fields, it offers a diverse range of practical training programs tailored for students, job seekers, and professionals. With a focus on hands-on learning and real-world applications, the platform bridges the gap between theoretical knowledge and career success.
                    The website provides expertly designed resources, tutorials, and certification programs to help learners stay competitive in today’s job market. Its user-friendly interface and personalized learning approach make it an ideal choice for individuals seeking to enhance their skills and unlock new career opportunities.
                    Whether you're a beginner or an experienced professional, Vivekananda College of Skill Development offers the tools and support you need to achieve your goals. Join today to transform your learning experience and stay ahead in your career journey.
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-end align-items-center">
              <div className="custom-circle bg-warning d-flex justify-content-center align-items-center">
                <img src="images/overview-img.png" alt="" className="overview-img" />
              </div>
            </div>
          </div>
          <div className="custom-bottom-left"></div>
        </div>
      </div>
      <div className="about-campus p-5">
        <div className="container-fluid px-0">
          <div className="row align-items-center">
            <div className="col-md-6 d-flex justify-content-center align-items-center position-relative">
              <div className="card bg-transparent">
                <div className="card-body p-0">
                  <div className="college-img-bg"></div>
                  <img src="images/college-students.jpg" alt="college-students" className="college-students" />
                </div>
              </div>
            </div>
            <div className="col-md-6 text-end">
              <h1 className="campus-heading">Campus Tour</h1>
              <div className="hr">
                <hr />
              </div>
              <div className="card">
                <div className="card-body">
                  <h5 className="fs-5 campus-text text-start mt-5">
                    Welcome to Vivekananda College of Skill Development, your gateway to a world of learning and career advancement. Our virtual campus is designed to provide an engaging and enriching educational experience for students, job seekers, and professionals alike.
                    Explore our comprehensive range of courses, covering fields like IT, software development, digital marketing, and more, all tailored to meet industry standards. Our interactive platform is equipped with expertly crafted tutorials, hands-on projects, and practical resources to ensure you gain real-world skills.
                    Navigate through our user-friendly website to discover flexible learning schedules, personalized guidance, and certification programs that help you stand out in today's competitive job market. Whether you're enhancing your skills or starting a new career journey, Vivekananda College of Skill Development provides the tools and support to make your goals achievable.
                    Join us and take a virtual tour of a campus where learning meets innovation, and success becomes a reality!
                  </h5>
                  <button className="btn shadow-none border-0 campus-btn rounded-pill px-4 px-2 fs-4 fw-bold me-5">Read More</button><span className="first-box"></span>
                  <span className="second-box"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="services p-5 pt-3">
        <div className="startline"></div>
        <h1 className="campus-heading">Services & <span className="heading">Amenities</span></h1>
        <div className="row align-items-center">
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <div className="img-bg-two"></div>
            <div className="img-bg mt-5">
              <img src="images/services.png" alt="service-img" className="service-img" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="card-bg"></div>
            <div className="card">
              <div className="card-body fs-5 font-500">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software.
              </div>
            </div>
            <button className="btn shadow-none border-0 services-btn rounded-pill px-4 px-2 fs-4 fw-bold me-5">Read More</button>
          </div>
        </div>
      </div>
      <div className="student-testimonials p-5">
        <h1 className="testimonials-heading text-center mb-5">Student Testimonials</h1>
        <>
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            navigation={true}
            loop={true}
            modules={[Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="card position-relative bg-transparent">
                <img src="https://img.freepik.com/premium-photo/successful-business-lady_274689-1067.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042" className="card-img-top rounded-top-5" alt="..." />
                <div className="card-body bg-light rounded-bottom-5">
                  <p className="card-text">"The teaching experience was outstanding! The instructor explained concepts with clarity, patience, and real-world examples. The interactive sessions made learning engaging and enjoyable. I felt motivated and confident throughout the course. It's the perfect blend of knowledge, support, and encouragement—highly recommended for anyone looking to learn effectively!"</p>
                  <a href="#" className="btn testimonials-btn px-4 py-2 border-0 shadow-none">Read More</a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card position-relative bg-transparent">
                <img src="https://img.freepik.com/premium-photo/portrait-charming-indian-girl-city_1048944-26670947.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042" className="card-img-top rounded-top-5" alt="..." />
                <div className="card-body bg-light rounded-bottom-5">
                  <p className="card-text">"The teaching experience was outstanding! The instructor explained concepts with clarity, patience, and real-world examples. The interactive sessions made learning engaging and enjoyable. I felt motivated and confident throughout the course. It's the perfect blend of knowledge, support, and encouragement—highly recommended for anyone looking to learn effectively!"</p>
                  <a href="#" className="btn testimonials-btn px-4 py-2 border-0 shadow-none">Read More</a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card position-relative bg-transparent">
                <img src="https://img.freepik.com/premium-photo/young-bulgarian-student-woman-outdoors-pointing-side-present-product_1368-499605.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042" className="card-img-top rounded-top-5" alt="..." />
                <div className="card-body bg-light rounded-bottom-5">
                  <p className="card-text">"The teaching experience was outstanding! The instructor explained concepts with clarity, patience, and real-world examples. The interactive sessions made learning engaging and enjoyable. I felt motivated and confident throughout the course. It's the perfect blend of knowledge, support, and encouragement—highly recommended for anyone looking to learn effectively!"</p>
                  <a href="#" className="btn testimonials-btn px-4 py-2 border-0 shadow-none">Read More</a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card position-relative bg-transparent">
                <img src="https://img.freepik.com/premium-photo/pretty-young-girl-holding-book-posing-grey-background_136354-6363.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042" className="card-img-top rounded-top-5" alt="..." />
                <div className="card-body bg-light rounded-bottom-5">
                  <p className="card-text">"The teaching experience was outstanding! The instructor explained concepts with clarity, patience, and real-world examples. The interactive sessions made learning engaging and enjoyable. I felt motivated and confident throughout the course. It's the perfect blend of knowledge, support, and encouragement—highly recommended for anyone looking to learn effectively!"</p>
                  <a href="#" className="btn testimonials-btn px-4 py-2 border-0 shadow-none">Read More</a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card position-relative bg-transparent">
                <img src="https://img.freepik.com/premium-photo/successful-business-lady_274689-1067.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042" className="card-img-top rounded-top-5" alt="..." />
                <div className="card-body bg-light rounded-bottom-5">
                  <p className="card-text">"The teaching experience was outstanding! The instructor explained concepts with clarity, patience, and real-world examples. The interactive sessions made learning engaging and enjoyable. I felt motivated and confident throughout the course. It's the perfect blend of knowledge, support, and encouragement—highly recommended for anyone looking to learn effectively!"</p>
                  <a href="#" className="btn testimonials-btn px-4 py-2 border-0 shadow-none">Read More</a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card position-relative bg-transparent">
                <img src="https://img.freepik.com/premium-photo/successful-business-lady_274689-1067.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042" className="card-img-top rounded-top-5" alt="..." />
                <div className="card-body bg-light rounded-bottom-5">
                  <p className="card-text">"The teaching experience was outstanding! The instructor explained concepts with clarity, patience, and real-world examples. The interactive sessions made learning engaging and enjoyable. I felt motivated and confident throughout the course. It's the perfect blend of knowledge, support, and encouragement—highly recommended for anyone looking to learn effectively!"</p>
                  <a href="#" className="btn testimonials-btn px-4 py-2 border-0 shadow-none">Read More</a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card position-relative bg-transparent">
                <img src="https://img.freepik.com/premium-photo/successful-business-lady_274689-1067.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042" className="card-img-top rounded-top-5" alt="..." />
                <div className="card-body bg-light rounded-bottom-5">
                  <p className="card-text">"The teaching experience was outstanding! The instructor explained concepts with clarity, patience, and real-world examples. The interactive sessions made learning engaging and enjoyable. I felt motivated and confident throughout the course. It's the perfect blend of knowledge, support, and encouragement—highly recommended for anyone looking to learn effectively!"</p>
                  <a href="#" className="btn testimonials-btn px-4 py-2 border-0 shadow-none">Read More</a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card position-relative bg-transparent">
                <img src="https://img.freepik.com/premium-photo/successful-business-lady_274689-1067.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042" className="card-img-top rounded-top-5" alt="..." />
                <div className="card-body bg-light rounded-bottom-5">
                  <p className="card-text">"The teaching experience was outstanding! The instructor explained concepts with clarity, patience, and real-world examples. The interactive sessions made learning engaging and enjoyable. I felt motivated and confident throughout the course. It's the perfect blend of knowledge, support, and encouragement—highly recommended for anyone looking to learn effectively!"</p>
                  <a href="#" className="btn testimonials-btn px-4 py-2 border-0 shadow-none">Read More</a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card position-relative bg-transparent">
                <img src="https://img.freepik.com/premium-photo/successful-business-lady_274689-1067.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042" className="card-img-top rounded-top-5" alt="..." />
                <div className="card-body bg-light rounded-bottom-5">
                  <p className="card-text">"The teaching experience was outstanding! The instructor explained concepts with clarity, patience, and real-world examples. The interactive sessions made learning engaging and enjoyable. I felt motivated and confident throughout the course. It's the perfect blend of knowledge, support, and encouragement—highly recommended for anyone looking to learn effectively!"</p>
                  <a href="#" className="btn testimonials-btn px-4 py-2 border-0 shadow-none">Read More</a>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </>
      </div>
      <div className='training-placement p-5'>
        <div className='training-placement-bg'></div>
        <div className='row'>
          <div className='col-md-6 d-flex justify-content-center align-items-center'>
            <div className="card w-75 h-100">
              <h2 className="card-title py-4 mt-1">Training and Placement Partners</h2>
              <div className="card-body">
                <div className='d-flex justify-content-around align-items-center'>
                  <div className="card">
                    <div className="card-body">
                      <img src='https://img.freepik.com/free-photo/working-urban-singapore-amazing-complex_1122-1722.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042&semt=ais_hybrid' alt='institution-1' />
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <img src='https://img.freepik.com/free-photo/working-urban-singapore-amazing-complex_1122-1722.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042&semt=ais_hybrid' alt='institution-2' />
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-around align-items-center'>
                  <div className="card">
                    <div className="card-body">
                      <img src='https://img.freepik.com/free-photo/working-urban-singapore-amazing-complex_1122-1722.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042&semt=ais_hybrid' alt='institution-1' />
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <img src='https://img.freepik.com/free-photo/working-urban-singapore-amazing-complex_1122-1722.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042&semt=ais_hybrid' alt='institution-2' />
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-around align-items-center'>
                  <div className="card">
                    <div className="card-body">
                      <img src='https://img.freepik.com/free-photo/working-urban-singapore-amazing-complex_1122-1722.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042&semt=ais_hybrid' alt='institution-1' />
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <img src='https://img.freepik.com/free-photo/working-urban-singapore-amazing-complex_1122-1722.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042&semt=ais_hybrid' alt='institution-2' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6 d-flex justify-content-center align-items-center'>
            <div className="card w-75 h-100">
              <h2 className="card-title py-4 mt-1">Notice</h2>
              <div className="card-body px-4">
                <ul>
                  <li>Important Notice</li>
                  <li>Important Notice</li>
                  <li>Important Notice</li>
                  <li>Important Notice</li>
                  <li>Important Notice</li>
                  <li>Important Notice</li>
                  <li>Important Notice</li>
                  <li>Important Notice</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="articles p-5">
        <center>
          <h1 className="articles-heading text-center mb-5">Articles</h1>
        </center>
        <>
          <Swiper
            slidesPerView={4}
            spaceBetween={30}
            navigation={true}
            loop={true}
            modules={[Navigation]}
            className="mySwiper p-5"
          >
            <SwiperSlide className='bg-transparent'>
              <div className="card shadow">
                <div className="card-body">
                  <div className="card mb-3">
                    <div className="row g-0">
                      <div className="col-md-5">
                        <img src="https://img.freepik.com/premium-photo/newspaper-background-concept_23-2149501615.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042&semt=ais_hybrid" className="img-fluid rounded-start" alt="articles" />
                      </div>
                      <div className="col-md-7">
                        <div className="card-body">
                          <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content.</p>
                  <hr />
                  <a href="#" className="btn articles-btn px-4 py-2 border-0 shadow-none float-end">Read More</a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className='bg-transparent'>
              <div className="card shadow">
                <div className="card-body">
                  <div className="card mb-3">
                    <div className="row g-0">
                      <div className="col-md-5">
                        <img src="https://img.freepik.com/premium-photo/newspaper-background-concept_23-2149501615.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042&semt=ais_hybrid" className="img-fluid rounded-start" alt="articles" />
                      </div>
                      <div className="col-md-7">
                        <div className="card-body">
                          <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content.</p>
                  <hr />
                  <a href="#" className="btn articles-btn px-4 py-2 border-0 shadow-none float-end">Read More</a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className='bg-transparent'>
              <div className="card shadow">
                <div className="card-body">
                  <div className="card mb-3">
                    <div className="row g-0">
                      <div className="col-md-5">
                        <img src="https://img.freepik.com/premium-photo/newspaper-background-concept_23-2149501615.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042&semt=ais_hybrid" className="img-fluid rounded-start" alt="articles" />
                      </div>
                      <div className="col-md-7">
                        <div className="card-body">
                          <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content.</p>
                  <hr />
                  <a href="#" className="btn articles-btn px-4 py-2 border-0 shadow-none float-end">Read More</a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className='bg-transparent'>
              <div className="card shadow">
                <div className="card-body">
                  <div className="card mb-3">
                    <div className="row g-0">
                      <div className="col-md-5">
                        <img src="https://img.freepik.com/premium-photo/newspaper-background-concept_23-2149501615.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042&semt=ais_hybrid" className="img-fluid rounded-start" alt="articles" />
                      </div>
                      <div className="col-md-7">
                        <div className="card-body">
                          <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content.</p>
                  <hr />
                  <a href="#" className="btn articles-btn px-4 py-2 border-0 shadow-none float-end">Read More</a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className='bg-transparent'>
              <div className="card shadow">
                <div className="card-body">
                  <div className="card mb-3">
                    <div className="row g-0">
                      <div className="col-md-5">
                        <img src="https://img.freepik.com/premium-photo/newspaper-background-concept_23-2149501615.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042&semt=ais_hybrid" className="img-fluid rounded-start" alt="articles" />
                      </div>
                      <div className="col-md-7">
                        <div className="card-body">
                          <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content.</p>
                  <hr />
                  <a href="#" className="btn articles-btn px-4 py-2 border-0 shadow-none float-end">Read More</a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className='bg-transparent'>
              <div className="card shadow">
                <div className="card-body">
                  <div className="card mb-3">
                    <div className="row g-0">
                      <div className="col-md-5">
                        <img src="https://img.freepik.com/premium-photo/newspaper-background-concept_23-2149501615.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042&semt=ais_hybrid" className="img-fluid rounded-start" alt="articles" />
                      </div>
                      <div className="col-md-7">
                        <div className="card-body">
                          <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content.</p>
                  <hr />
                  <a href="#" className="btn articles-btn px-4 py-2 border-0 shadow-none float-end">Read More</a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className='bg-transparent'>
              <div className="card shadow">
                <div className="card-body">
                  <div className="card mb-3">
                    <div className="row g-0">
                      <div className="col-md-5">
                        <img src="https://img.freepik.com/premium-photo/newspaper-background-concept_23-2149501615.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042&semt=ais_hybrid" className="img-fluid rounded-start" alt="articles" />
                      </div>
                      <div className="col-md-7">
                        <div className="card-body">
                          <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content.</p>
                  <hr />
                  <a href="#" className="btn articles-btn px-4 py-2 border-0 shadow-none float-end">Read More</a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className='bg-transparent'>
              <div className="card shadow">
                <div className="card-body">
                  <div className="card mb-3">
                    <div className="row g-0">
                      <div className="col-md-5">
                        <img src="https://img.freepik.com/premium-photo/newspaper-background-concept_23-2149501615.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042&semt=ais_hybrid" className="img-fluid rounded-start" alt="articles" />
                      </div>
                      <div className="col-md-7">
                        <div className="card-body">
                          <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content.</p>
                  <hr />
                  <a href="#" className="btn articles-btn px-4 py-2 border-0 shadow-none float-end">Read More</a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className='bg-transparent'>
              <div className="card shadow">
                <div className="card-body">
                  <div className="card mb-3">
                    <div className="row g-0">
                      <div className="col-md-5">
                        <img src="https://img.freepik.com/premium-photo/newspaper-background-concept_23-2149501615.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042&semt=ais_hybrid" className="img-fluid rounded-start" alt="articles" />
                      </div>
                      <div className="col-md-7">
                        <div className="card-body">
                          <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content.</p>
                  <hr />
                  <a href="#" className="btn articles-btn px-4 py-2 border-0 shadow-none float-end">Read More</a>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </>
      </div>
      <div className='Footer p-5 pb-0'>
        <div className="card">
          <div className="card-body p-0">
            <div className='row'>
              <div className='col-md-4'>
                <div className="card border-end">
                  <div className="card-body">
                    <h5 className="card-title fw-bold fs-4">Contact Us:</h5>
                    <ul className='ps-0'>
                      <li className='my-3 fs-5'><a href="tel:+919432155145" target='_blank'><i className="fa-solid fa-phone me-3"></i>9432155145</a></li>
                      <li className='my-3 fs-5'><a href="https://vcskilldevelopment.in/"><i className="fa-solid fa-globe me-3"></i>vcskilldevelopment.in</a></li>
                      <li className='my-3 fs-5'><a href="mailto:vcskilldevelopment@gmail.com" target='_blank'><i className="fa-solid fa-envelope me-3"></i>vcskilldevelopment@gmail.com</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className="card border-end h-100">
                  <div className="card-body">
                    <h5 className="card-title fw-bold fs-4">Links:</h5>
                    <ul className='ps-0'>
                      <li className='my-3 fs-5'></li>
                      <li className='my-3 fs-5'></li>
                      <li className='my-3 fs-5'></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className="card h-100">
                  <div className="card-body">
                    <h5 className="card-title fw-bold fs-4">Address:</h5>
                    <ul className='ps-0'>
                      <li className='my-3 fs-5'><a href="https://maps.app.goo.gl/BcX5JHH9ic7G9jLi7" target='_blank'><i className="fa-solid fa-location-dot me-3"></i>26, Ananda Mohan Bose Rd, Ward Number 22, Motijheel, Amarpalli, Dum Dum, Kolkata, West Bengal 700074</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='Footer-bottom'>
        <div className="card border-0">
          <div className="card-body">
            <a href='' className='mx-3 fs-2'><i class="fa-brands fa-linkedin"></i></a>
            <a href='' className='mx-3 fs-2'><i class="fa-brands fa-whatsapp"></i></a>
            <a href='' className='mx-3 fs-2'><i class="fa-brands fa-instagram"></i></a>
            <a href='' className='mx-3 fs-2'><i class="fa-brands fa-facebook"></i></a>
          </div>
        </div>
      </div>
      <div className='Footer-bottom-two'>
        <div className="card border-0">
          <div className="card-body">
            <p className='mb-0 fs-4'>Copyright @ Vivekananda College of Skill Development | All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </div>
  );
}


export default App;


